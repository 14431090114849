import React, { FC } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { BeeItem, GradientShadow } from "src/components/elements";
import { Header } from "src/components/layouts";
import ShowcaseFooter from "./ShowcaseFooter";

type ShowcaseProps = {};

const Showcase: FC<ShowcaseProps> = (props) => {
  return (
    <SimpleGrid position="relative" columns={{ base: 2, md: 4 }} height="100vh">
      <Header />
      <BeeItem color="red" />
      <BeeItem color="blue" />
      <BeeItem color="yellow" />
      <BeeItem color="green" />
      <GradientShadow />
      <ShowcaseFooter />
    </SimpleGrid>
  );
};

export default Showcase;
