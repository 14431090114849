import React, { FC } from "react";
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from "@chakra-ui/react";

type CampaignPopupProps = {
  disclosure: UseDisclosureReturn;
};

const CampaignPopup: FC<CampaignPopupProps> = (props) => {
  return (
    <Modal size="lg" isOpen={props.disclosure.isOpen} onClose={props.disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton autoFocus={false} />
        <ModalBody>
          <Image w="full" h="full" src="/assets/popup.jpeg" />
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CampaignPopup;
