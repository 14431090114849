import React, { FC } from "react";
import {
  Box,
  Flex,
  HStack,
  Text,
  Image,
  Container,
  Heading,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { GradientShadow } from "src/components/elements";
import Carousel from "src/components/elements/Carousel/Carousel";
import { useMediaQueries } from "src/hooks/useMediaQueries";

type AboutProps = {};

const About: FC<AboutProps> = (props) => {
  const { isMd } = useMediaQueries();
  return (
    <Box
      height="100vh"
      overflow="hidden"
      bgImage="/assets/backgrounds/about_bg.png"
      backgroundSize="cover"
      position="relative"
    >
      <Box position="absolute" h="full" w="full"></Box>
      <Container
        maxWidth="container.lg"
        zIndex="banner"
        position="relative"
        py="10"
        h="full"
      >
        <VStack justifyContent="space-around" h="full">
          <Heading
            fontFamily="Scrubland"
            letterSpacing="1px"
            textAlign="center"
            maxW="300px"
            textShadow="0px 0px 20px rgba(0, 0, 0, 1)"
          >
            What Can You Do In BeeWorld ?
          </Heading>

          <Carousel>
            <Box pb="10">
              <Flex
                gap={{ base: 0, lg: 10 }}
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
              >
                {!isMd && (
                  <Image
                    maxWidth="200px"
                    flexShrink="0"
                    src="/assets/items/stones.png"
                  />
                )}
                <Text
                  fontFamily="IndieFlower"
                  textAlign="center"
                  fontSize="xl"
                  color="white"
                  textShadow="0px 0px 10px  #b1b802"
                  lineHeight="1.2"
                  maxW="650px"
                  flexGrow="1"
                  w="full"
                >
                  You can start the game by choosing any of the Ah-Muzen, Beyla,
                  Mellona, ​​Tears of Ra bee nations inspired by the Mayan
                  Norse, Roman, and Egyptian mythologies, and work for the
                  progress of your nation. You can have one or more of the
                  Worker, Drone or Queen bees and start producing HNY.
                </Text>
              </Flex>
              <Flex
                mt="2"
                gap="10"
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
              >
                <Text
                  textAlign="center"
                  fontSize="xl"
                  color="white"
                  textShadow="0px 0px 10px  #b1b802"
                  lineHeight="1.2"
                  maxW="650px"
                  flexGrow="1"
                  w="full"
                  fontFamily="IndieFlower"
                >
                  According to the BeePower you have, you can determine the most
                  efficient hive for you and try to protect your place in the
                  hive. (Remember, it's not all about BeePower. In this HNY
                  production race, you can get ahead of bees with much more
                  BeePower than you by being strategic :)
                </Text>
                {!isMd && (
                  <Image
                    maxWidth="100px"
                    flexShrink="0"
                    src="/assets/items/foco.png"
                    display={{ base: "none", md: "block" }}
                  />
                )}
              </Flex>
            </Box>
            <Box pb="10">
              <Flex
                gap={{ base: 0, lg: 10 }}
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
              >
                {!isMd && (
                  <Image
                    maxWidth="200px"
                    flexShrink="0"
                    src="/assets/items/cup.png"
                  />
                )}
                <Text
                  fontFamily="IndieFlower"
                  textAlign="center"
                  fontSize="xl"
                  color="white"
                  textShadow="0px 0px 10px  #b1b802"
                  lineHeight="1.2"
                  maxW="650px"
                  flexGrow="1"
                  w="full"
                >
                  In weekly BeeWar events, you can get your nation a chance to
                  rank up to the top and earn rewards like HNY Production Bonus
                  and NFT airdrops. You can exile other nations' bees and try to
                  sabotage the HNY they earn, to make sure your nation will earn
                  these rewards :)
                </Text>
              </Flex>
              <Flex
                mt="2"
                gap={{ base: 0, lg: 10 }}
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
              >
                <Text
                  fontFamily="IndieFlower"
                  textAlign="center"
                  fontSize="xl"
                  color="white"
                  textShadow="0px 0px 10px  #b1b802"
                  lineHeight="1.2"
                  maxW="650px"
                  flexGrow="1"
                  w="full"
                >
                  You can start the game by choosing any of the Ah-Muzen, Beyla,
                  Mellona, ​​Tears of Ra bee nations inspired by the Mayan
                  Norse, Roman, and Egyptian mythologies, and work for the
                  progress of your nation. You can have one or more of the
                  Worker, Drone or Queen bees and start producing HNY.
                </Text>
                {!isMd && (
                  <Image
                    maxWidth="100px"
                    flexShrink="0"
                    src="/assets/items/money.png"
                  />
                )}
              </Flex>
            </Box>
            <Box pb="10">
              <Flex
                gap={{ base: 0, lg: 10 }}
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
              >
                {!isMd && (
                  <Image
                    maxWidth="200px"
                    flexShrink="0"
                    src="/assets/items/cog.png"
                  />
                )}
                <Text
                  textAlign="center"
                  fontFamily="IndieFlower"
                  fontSize="xl"
                  color="white"
                  textShadow="0px 0px 10px  #b1b802"
                  lineHeight="1.2"
                  maxW="650px"
                  flexGrow="1"
                  w="full"
                >
                  You can start the game by choosing any of the Ah-Muzen, Beyla,
                  Mellona, ​​Tears of Ra bee nations inspired by the Mayan
                  Norse, Roman, and Egyptian mythologies, and work for the
                  progress of your nation. You can have one or more of the
                  Worker, Drone or Queen bees and start producing HNY.
                </Text>
              </Flex>
              <Flex
                mt="2"
                gap={{ base: 0, lg: 10 }}
                justifyContent="center"
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
              >
                <Text
                  textAlign="center"
                  fontSize="xl"
                  color="white"
                  textShadow="0px 0px 10px  #b1b802"
                  lineHeight="1.2"
                  maxW="650px"
                  flexGrow="1"
                  fontFamily="IndieFlower"
                  w="full"
                >
                  You can start the game by choosing any of the Ah-Muzen, Beyla,
                  Mellona, ​​Tears of Ra bee nations inspired by the Mayan
                  Norse, Roman, and Egyptian mythologies, and work for the
                  progress of your nation. You can have one or more of the
                  Worker, Drone or Queen bees and start producing HNY.
                </Text>
                {!isMd && (
                  <Image
                    maxWidth="150px"
                    flexShrink="0"
                    src="/assets/items/money-tree.png"
                  />
                )}
              </Flex>
            </Box>
          </Carousel>
        </VStack>
      </Container>
    </Box>
  );
};

export default About;
