import { Box } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
import Header from "./Header/Header";

type LayoutProps = PropsWithChildren<{}>;

const Layout: FC<LayoutProps> = (props) => {
  return (
    <Box>
      {props.children}
    </Box>
  );
};

export default Layout;
