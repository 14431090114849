import React, { FC } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

type GradientShadowProps = {};

const GradientShadow: FC<GradientShadowProps> = (props) => {
  return (
    <Box
      position="absolute"
      bgGradient="linear(to-b, blackAlpha.900, blackAlpha.400, transparent, blackAlpha.400, blackAlpha.900)"
      w="full"
      h="full"
      zIndex="base"
    ></Box>
  );
};

export default GradientShadow;
