import React, { FC, PropsWithChildren } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as RCarousel } from "react-responsive-carousel";

type CarouselProps = PropsWithChildren<{}>;

const Carousel: FC<CarouselProps> = (props) => {
  return <RCarousel showArrows={false} showThumbs={false} showStatus={false}>{props.children as any[]}</RCarousel>;
};

export default Carousel;
