import React, { FC } from "react";
import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import SocialLink from "./SocialLink";
import { FaTwitter, FaFacebook, FaDiscord, FaMedium } from "react-icons/fa";

type FooterProps = {};

const Footer: FC<FooterProps> = (props) => {
  return (
    <Box
      minH="100vh"
      bgImage="/assets/backgrounds/nft-2.png"
      bgSize="cover"
      backgroundPosition="center"
    >
      <VStack justifyContent="flex-end"  minH="100vh" pb="10%" pt="33%" spacing="20">
        <HStack spacing="10">
          <SocialLink icon={FaDiscord} href="https://discord.gg/NPVYdCcgBD" />
          <SocialLink icon={FaMedium} href="https://medium.com/@beeworlds"  />
          <SocialLink icon={FaTwitter} href="https://twitter.com/BeeWorlds_io" />
        </HStack>
        <Text textAlign="center" fontWeight="semibold" fontSize="lg">
          COPYRIGHT © 2022 BEEWORLDS ALL RIGHTS DESERVED
        </Text>
      </VStack>
    </Box>
  );
};

export default Footer;
