import React, { FC } from "react";
import { Box, Center, Flex, Heading, Text } from "@chakra-ui/react";

type SheetItemProps = {
  title: string;
  subTitle: string;
  contents?: string[];
  comingSoon?: boolean;
};

const SheetItem: FC<SheetItemProps> = (props) => {
  return (
    <Box
      bgImage="/assets/items/sheet-item.png"
      bgSize="100% 100%"
      bgRepeat="no-repeat"
      minH="400px"
      py="20%"
      px="15%"
    >
      <Text
        textAlign="center"
        color="orange.50"
        fontWeight="bold"
        textTransform="uppercase"
        fontSize="3xl"
        textShadow="2px 0 0 #c88d04, -2px 0 0 #c88d04, 0 2px 0 #c88d04, 0 -2px 0 #c88d04, 1px 1px #c88d04, -1px -1px 0 #c88d04, 1px -1px 0 #c88d04, -1px 1px 0 #c88d04"
      >
        {props.title}
      </Text>
      <Text
        mt="-2"
        mb="5"
        textAlign="center"
        color="orange.50"
        textShadow="2px 0 0 #c88d04, -2px 0 0 #c88d04, 0 2px 0 #c88d04, 0 -2px 0 #c88d04, 1px 1px #c88d04, -1px -1px 0 #c88d04, 1px -1px 0 #c88d04, -1px 1px 0 #c88d04"
        fontWeight="bold"
        fontSize="lg"
      >
        {props.subTitle}
      </Text>

      {props.contents &&
        props.contents.map((content, index) => (
          <Box mt="2" key={index}>
            <Text
              fontFamily="IndieFlower"
              fontWeight="semibold"
              fontSize="15px"
              color="gray.600"
            >
              - {content}
            </Text>
          </Box>
        ))}
      {props.comingSoon && (
        <Center h="80%">
          <Text
            fontSize={{ base: "3xl", lg: "5xl" }}
            fontFamily="IndieFlower"
            textAlign="center"
            color="gray.600"
          >
            Coming Soon
          </Text>
        </Center>
      )}
    </Box>
  );
};

export default SheetItem;
