import React, { FC } from "react";
import { Box, Center, Flex, Text } from "@chakra-ui/react";

type NftItemProps = {
  title: string;
};

const NftItem: FC<NftItemProps> = (props) => {
  return (
    <Flex
      flexDir="column"
      bgImage="/assets/items/nft-card.png"
      bgSize="100% 100%"
      bgRepeat="no-repeat"
      minH="400px"
      justifyContent="flex-end"
    >
      <Center pb="7">
        <Text fontFamily="IndieFlower" fontSize="4xl" textShadow="0px 0px 5px black">{props.title}</Text>
      </Center>
    </Flex>
  );
};

export default NftItem;
