import React, { FC } from "react";
import { Box, Center, Flex, HStack, Text } from "@chakra-ui/react";
import PlayNowButton from "./PlayNowButton";

type ShowcaseFooterProps = {};

const ShowcaseFooter: FC<ShowcaseFooterProps> = (props) => {
  return (
    <Box
      position="absolute"
      bottom="0"
      textAlign="center"
      w="full"
      py="10"
      zIndex="banner"
      bgGradient="linear(to-t, blackAlpha.500, blackAlpha.500, transparent)"
    >
      <Center>
        <Text
        maxW="700px"
          textAlign="center"
          fontSize={{base: "xl", md: "2xl"}}
          fontWeight="semibold"
          color="white"
          textShadow="0px 0px 3px #b1b802"
          lineHeight="1.2"
        >
          BeeWorlds is an Nft-Based Real-Time Strategy(RTS) game where players
          can act like cyborg bees.
        </Text>
      </Center>
      <HStack alignItems="center" justifyContent="center" mt="10">
        {/* <PlayNowButton /> */}
      </HStack>
    </Box>
  );
};

export default ShowcaseFooter;
