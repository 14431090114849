import React, { FC } from "react";
import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import SheetItem from "./SheetItem";

type RoadMapProps = {};

const RoadMap: FC<RoadMapProps> = (props) => {
  return (
    <Box
      minH="100vh"
      bgImage="/assets/backgrounds/roadmap_bg.png"
      bgSize="cover"
    >
      <Container py="10" maxWidth="container.xl">
        <VStack w="full">
          <Heading
            fontFamily="Scrubland"
            letterSpacing="1px"
            textAlign="center"
            maxW="300px"
            textShadow="0px 0px 20px rgba(0, 0, 0, 1)"
          >
            Roadmap
          </Heading>
          <SimpleGrid
            minH="300px"
            w="full"
            spacing="2"
            columns={{ base: 1, sm: 1, md: 2, lg: 4 }}
          >
            <SheetItem
              title="Phase 1"
              subTitle="Expansion"
              contents={[
                "Publishing sneak peek about continent and bee images",
                "Publishing the Webs'te",
                "Investor Nft Sale",
                "AtomicHub Whitelist",
                "Passport Sale",
                "Release of Vom'a 10B",
                "First Bee Pack Sale",
                "Release of the first in-game images",
                "First Hive Pack Sale",
                "First Garden Pack Sale",
                "Release of HNY Token",
              ]}
            />
            <SheetItem
              title="Phase 2"
              subTitle="BeePlanet"
              contents={[
                "Starting of HNY Production",
                "Adding an Energy Pack",
                "Adding Lucky Bee Pack and BeePot",
                "Staking Integration",
                "Withdraw Integration",
                "Integration of Exile System",
                "Integration of Shield/Sword system",
                "Second Hive Sale",
                "Integrating of Hive Ownership",
                "Second Garden Sale",
                "Integrating of Garden Ownership",
                "MarketPlace Integration",
              ]}
            />
            <SheetItem
              title="PHASE 3"
              subTitle="Beeverse"
              contents={[
                "Release of the Mainland",
                "Integration of BeeWar",
                "Id Pack Sale",
                "Relace of Commercial Hub",
                "Buildings Integration",
                "Integration of the job system",
                "Mobile Integration",
                "BDAO Token sale",
                "Dao Integration",
              ]}
            />
            <SheetItem title="Phase 4" subTitle="Secret Phase" comingSoon />
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};

export default RoadMap;
