import React, { FC } from "react";
import { Box, Flex, Text, Link, Center, Icon } from "@chakra-ui/react";

type SocialLinkProps = {
  link?: string;
  icon?: any;
  href?: string;
};

const SocialLink: FC<SocialLinkProps> = (props) => {
  return (
    <Link href={props.href} target="_blank">
      <Center transform="rotate(45deg)" bgColor="blackAlpha.100" p="4">
        <Icon
          color="white"
          transform="rotate(-45deg)"
          as={props.icon}
          fontSize="5xl"
        />
      </Center>
    </Link>
  );
};

export default SocialLink;
