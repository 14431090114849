import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./styles/fonts.css";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

const theme = extendTheme({
  colors: {
    orange: {
      100: "#feefcd",
      200: "#fddf9b",
      300: "#fcd069",
      400: "#fbc037",
      500: "#fab005",
      600: "#c88d04",
      700: "#966a03",
      800: "#644602",
      900: "#322301",
    },
    navy: {
      100: "#d0d8e2",
      200: "#a1b0c5",
      300: "#7289a8",
      400: "#43618b",
      500: "#143a6e",
      600: "#102e58",
      700: "#0c2342",
      800: "#08172c",
      900: "#040c16",
    },
  },
  fonts: {
    heading: "Scrubland, Comic Sans MS",
    body: "Montserrat",
  },
  components: {
    Text: {
      baseStyle: {
        color: "white",
      },
    },
    Heading: {
      baseStyle: {
        color: "white",
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode="dark" />
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
