import React, { FC, useEffect, useState } from "react";
import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { Layout } from "src/components/layouts";
import ReactPageScroller from "react-page-scroller";
import Showcase from "./Showcase/Showcase";
import Story from "./Story/Story";
import About from "./About/About";
import RoadMap from "./RoadMap/RoadMap";
import { useMediaQueries } from "src/hooks/useMediaQueries";
import Nfts from "./Nfts/Nfts";
import Footer from "./Footer/Footer";
import CampaignPopup from "src/components/elements/CampaignPopup/CampaignPopup";

type HomePageProps = {};

const HomePage: FC<HomePageProps> = (props) => {
  const disc = useDisclosure();
  const { isMd } = useMediaQueries();

  useEffect(() => {
    setTimeout(() => {
      disc.onOpen();
    }, 500);
  }, []);

  if (isMd) {
    return (
      <Layout>
        <Showcase />
        <Story />
        <About />
        <RoadMap />
        <Nfts />
        <Footer />
        <CampaignPopup disclosure={disc} />
      </Layout>
    );
  }

  return (
    <Layout>
      <ReactPageScroller>
        <Showcase />
        <Story />
        <About />
        <RoadMap />
        <Nfts />
        <Footer />
      </ReactPageScroller>
      <CampaignPopup disclosure={disc} />
    </Layout>
  );
};

export default HomePage;
