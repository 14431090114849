import React, { FC } from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  Image,
  useMediaQuery,
  theme,
} from "@chakra-ui/react";
import HeaderLink from "./HeaderLink";
import { useMediaQueries } from "src/hooks/useMediaQueries";

type HeaderProps = {};

const Header: FC<HeaderProps> = (props) => {
  const { isMd } = useMediaQueries();
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      w="full"
      h="100px"
      zIndex="banner"
      bgGradient="linear(to-b, blackAlpha.900, transparent)"
    >
      {!isMd ? (
        <Container maxWidth="container.xl" h="full">
          <Flex alignItems="center" h="full">
            <HeaderLink title="STORY" flex="1" />
            <HeaderLink title="ROADMAP" flex="1" />
            <HeaderLink title="NFTS" flex="1" />
            <Center flex="1" mt="3">
              <Image src="/assets/logos/bee-worlds.png" w="auto" h="auto" />
            </Center>
            <HeaderLink title="PLAY" flex="1" />
            <HeaderLink
              title="WHITEPAPER"
              flex="1"
              onClick={() => {
                window.open("https://docs.beeworlds.io/");
              }}
            />
            <HeaderLink
              title="ATOMICHUB"
              flex="1"
              onClick={() => {
                window.open("https://wax.atomichub.io/explorer/collection/beeworlds");
              }}
            />
          </Flex>
        </Container>
      ) : (
        <Center>
          <Image src="/assets/logos/bee-worlds.png" w="auto" h="auto" />
        </Center>
      )}
    </Box>
  );
};

export default Header;
