import React, { FC, useMemo, useState } from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import NftItem from "./NftItem";

type NftsProps = {};

const Nfts: FC<NftsProps> = (props) => {
  const [state, setState] = useState({ step: 0 });

  const nfts = useMemo(() => {
    let a = [];
    a[0] = "Tikal, Niflheim, Vulcano, Per-Bit".split(", ");
    a[1] = "Small Bee Pack I, Big Bee Pack I, Hive Pack I, Garden Pack I".split(
      ", "
    );
    a[2] = "Ah-Muzen, Beyla, Mellona, Tears of Ra".split(", ");
    a[3] = "Peaceful, Normal, Competitive, Unstable".split(", ");
    a[4] = "Blue, Yellow, Green, Red".split(", ");
    return a[state.step];
  }, [state.step]);

  return (
    <Center
      minH="100vh"
      h="100%"
      bgImage="/assets/backgrounds/nft-1.png"
      backgroundPosition="center"
      bgSize="cover"
    >
      <Container py="10" maxWidth="container.xl" h="100%">
        <VStack
          w="full"
          justifyContent="center"
          h="full"
          spacing={{ base: 5, lg: 10 }}
        >
          <Heading
            fontFamily="Scrubland"
            letterSpacing="1px"
            textAlign="center"
            maxW="300px"
            textShadow="0px 0px 20px rgba(0, 0, 0, 1)"
          >
            NFT
          </Heading>
          <Box bgColor="navy.500" py={{ base: 6, lg: 2 }} rounded="lg">
            <Flex
              flexDirection={{ base: "column", lg: "row" }}
              alignItems="center"
              px="10"
              justifyContent="space-between"
              gap={{ base: 10, lg: 20 }}
            >
              <Link href="https://wax.atomichub.io/drops/91028" target="_blank">
                <Text fontSize="lg" fontWeight="bold">
                  Investor NFT
                </Text>
              </Link>
              <SimpleGrid
                columns={{ base: 2, lg: 5 }}
                spacing={{ base: 2, lg: 10 }}
              >
                <Box
                  py="4"
                  px="5"
                  bgColor={state.step === 0 ? "navy.300" : "navy.500"}
                  rounded="lg"
                  cursor="pointer"
                  _hover={{ bgColor: "navy.400" }}
                  onClick={() => setState((x) => ({ ...x, step: 0 }))}
                  textAlign="center"
                >
                  <Text>Passport</Text>
                  <Text fontSize="xs">COMING SOON</Text>
                </Box>

                <Box
                  py="4"
                  px="5"
                  bgColor={state.step === 1 ? "navy.300" : "navy.500"}
                  rounded="lg"
                  cursor="pointer"
                  textAlign="center"
                  _hover={{ bgColor: "navy.400" }}
                  onClick={() => setState((x) => ({ ...x, step: 1 }))}
                >
                  <Text>Packs</Text>
                  <Text fontSize="xs">COMING SOON</Text>
                </Box>
                <Box
                  py="4"
                  px="5"
                  bgColor={state.step === 2 ? "navy.300" : "navy.500"}
                  rounded="lg"
                  cursor="pointer"
                  textAlign="center"
                  _hover={{ bgColor: "navy.400" }}
                  onClick={() => setState((x) => ({ ...x, step: 2 }))}
                >
                  <Text>Bees</Text>
                  <Text fontSize="xs">COMING SOON</Text>
                </Box>
                <Box
                  py="4"
                  px="5"
                  bgColor={state.step === 3 ? "navy.300" : "navy.500"}
                  rounded="lg"
                  cursor="pointer"
                  textAlign="center"
                  _hover={{ bgColor: "navy.400" }}
                  onClick={() => setState((x) => ({ ...x, step: 3 }))}
                >
                  <Text>Hives</Text>
                  <Text fontSize="xs">COMING SOON</Text>
                </Box>
                <Box
                  py="4"
                  px="5"
                  bgColor={state.step === 4 ? "navy.300" : "navy.500"}
                  rounded="lg"
                  cursor="pointer"
                  textAlign="center"
                  _hover={{ bgColor: "navy.400" }}
                  onClick={() => setState((x) => ({ ...x, step: 4 }))}
                >
                  <Text>Gardens</Text>
                  <Text fontSize="xs">COMING SOON</Text>
                </Box>
              </SimpleGrid>
            </Flex>
          </Box>
          <SimpleGrid
            minH="300px"
            w="full"
            spacing="2"
            columns={{ base: 2, sm: 2, md: 2, lg: 4 }}
          >
            {nfts.map((_nftTitle, index) => (
              <NftItem title={_nftTitle} key={index} />
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    </Center>
  );
};

export default Nfts;
