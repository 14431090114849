import React, { FC } from "react";
import {
  Center,
  Text,
  Image,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import GradientShadow from "../GradientShadow";
import { useMediaQueries } from "src/hooks/useMediaQueries";

type BeeItemProps = {
  backgroundUrl?: string;
  imageUrl?: string;
  hasPerspective?: boolean;
  color: string;
};

const BeeItem: FC<BeeItemProps> = (props) => {
  const modalDisclosure = useDisclosure();
  const { isMd } = useMediaQueries();
  return (
    <Center
      onClick={modalDisclosure.onOpen}
      bgImg={`/assets/home-page/bees/${props.color}-bg.png`}
      bgSize="cover"
      position="relative"
      bgPosition="50% 80%"
      borderX="2px"
      borderColor="white"
      _last={{ borderRight: "0" }}
      _first={{ borderLeft: "0" }}
      role="group"
      cursor="pointer"
    >
      <Image src={`/assets/home-page/bees/${props.color}-bee.png`} zIndex="1" />
      {!isMd ? (
        <Center
          p="10"
          position="absolute"
          bgColor="blackAlpha.900"
          w="full"
          h="full"
          zIndex="2"
          visibility="hidden"
          opacity="0"
          transition="300ms"
          _groupHover={{ visibility: "visible", opacity: 1 }}
        >
          <Text color="white" fontSize="lg">
            {props.color === "red" &&
              `They are cyborg bees with the Mellona modes activated, located on the continent of Vulcano.
            They can produce HNY more efficiently in volcanic areas. They are resistant to fire, lava, and high temperatures.`}
            {props.color === "blue" &&
              `They are cyborg bees with the Beyla modes activated, located on the continent of Niflheim.
            They can produce HNY more efficiently in glacial areas and their cold resistance is very high.`}
            {props.color === "green" &&
              `They are cyborg bees with the Ah-Muzen modes activated, located on the continent of Tikal.
            They can produce HNY more efficiently in forested areas and better camouflage against dangers.`}
            {props.color === "yellow" &&
              `They are cyborg bees with the Tears of Ra modes activated, located on the continent of Per-Bit.
              They can produce HNY more efficiently in desert regions. They are resistant to high-temperature changes and sandstorms.`}
          </Text>
        </Center>
      ) : (
        <Modal {...modalDisclosure} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody rounded="xl" bgColor="black">
              <Center minH="50vh">
                <Text color="white" fontSize="lg" textAlign="center">
                  {props.color === "red" &&
                    `They are cyborg bees with the Mellona modes activated, located on the continent of Vulcano.
          They can produce HNY more efficiently in volcanic areas. They are resistant to fire, lava, and high temperatures.`}
                  {props.color === "blue" &&
                    `They are cyborg bees with the Beyla modes activated, located on the continent of Niflheim.
          They can produce HNY more efficiently in glacial areas and their cold resistance is very high.`}
                  {props.color === "green" &&
                    `They are cyborg bees with the Ah-Muzen modes activated, located on the continent of Tikal.
          They can produce HNY more efficiently in forested areas and better camouflage against dangers.`}
                  {props.color === "yellow" &&
                    `They are cyborg bees with the Tears of Ra modes activated, located on the continent of Per-Bit.
                    They can produce HNY more efficiently in desert regions. They are resistant to high-temperature changes and sandstorms.`}
                </Text>
              </Center>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Center>
  );
};

export default BeeItem;
