import React, { FC } from "react";
import { Box, Center, Flex, Text } from "@chakra-ui/react";

type HeaderLinkProps = {
  flex: string;
  title: string;
  onClick?: () => void;
};

const HeaderLink: FC<HeaderLinkProps> = (props) => {
  return (
    <Center
      flex={props.flex}
      as="button"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <Text
        fontFamily="Montserrat"
        fontSize="lg"
        textShadow="0px 3px 1px black"
        fontWeight="bold"
      >
        {props.title}
      </Text>
    </Center>
  );
};

export default HeaderLink;
