import { useMediaQuery, theme } from "@chakra-ui/react";
import { useMemo } from "react";

export function useMediaQueries() {
  const [isBase] = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [isSm] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [isMd] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  const [isLg] = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);
  const [isXl] = useMediaQuery(`(max-width: ${theme.breakpoints["2xl"]})`);
  const [is2xl] = useMediaQuery(`(min-width: ${theme.breakpoints["2xl"]})`);

  const isMobile = useMemo(() => isBase || isSm || isMd, [isBase, isSm, isMd]);
  const isDesktop = useMemo(() => isLg || isXl || is2xl, [isLg, isXl, is2xl]);

  return { isBase, isSm, isMd, isLg, isXl, is2xl, isMobile, isDesktop };
}
