import { FC } from "react";
import { Box, Heading, Image, VStack } from "@chakra-ui/react";
import { GradientShadow } from "src/components/elements";

type StoryProps = {};

const Story: FC<StoryProps> = (props) => {
  return (
    <Box
      height="100vh"
      bgColor="pink.500"
      bgImage="/assets/backgrounds/story_bg.png"
      backgroundSize="cover"
      position="relative"
    >
      <GradientShadow />
      <VStack
        justifyContent="space-between"
        h="full"
        py="20"
        position="relative"
        zIndex="banner"
      >
        <Heading
          fontFamily="Scrubland"
          letterSpacing="1px"
          textShadow="0px 0px 20px rgba(0, 0, 0, 1)"
        >
          Story
        </Heading>
        <Image maxH="100vh" src="/assets/items/book.png" />
        <Box></Box>
      </VStack>
    </Box>
  );
};

export default Story;
